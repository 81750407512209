/* Dev Name: Valentine Bassey. 
   Email: awasevalentine@gmail.com
   Github: https://github.com/awasevalentine
*/

.modal-header{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none !important;
}

.logo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #047857;
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;
    align-items: center;
}

.close_btn{
    width: 70px;
    border: 1px solid silver;
    border-radius: 5px;
    padding: 5px;
    text-decoration: none;
    text-align: center;
    color: black;
}




/* Section for medial queries */

@media screen and (max-width: 320px) {
}

/* For smaller screen */
  @media screen and (min-width: 321px) and (max-width: 478px) {
  }


/* Small screen */
@media screen and (min-width: 479px) and (max-width: 767px) {
}


/* Media Query section for  Medium screen */
@media screen and (min-width: 768px) and (max-width: 991px){
}

/* Media Query section for  Medium screen */
@media screen and (min-width: 992px) and (max-width: 1200px){
}