
.modal1{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.loader1 {
    /* margin: 10% 45%; */
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #2980B9;
    border-right: 10px solid #2ECC71;
    border-bottom: 10px solid #e62020;
    border-left: 10px solid #f5ba1a;
    width: 70px;
    height: 70px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

  /* Media screen section */

  
  /* For extra smaller screen */
  @media screen and (max-width: 320px) {

    .loader1 {
        /* margin: 10% 45%; */
        display: flex;
        justify-content: center;
        align-items: center;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 6px solid #2980B9;
        border-right: 6px solid #2ECC71;
        border-bottom: 6px solid #e62020;
        border-left: 6px solid #f5ba1a;
        width: 50px;
        height: 50px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

  }
  /* End of extra smaller screen */

  /* For smaller screen */
@media screen and (min-width: 321px) and (max-width: 478px) {

    .loader1 {
        /* margin: 10% 45%; */
        display: flex;
        justify-content: center;
        align-items: center;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 6px solid #2980B9;
        border-right: 6px solid #2ECC71;
        border-bottom: 6px solid #e62020;
        border-left: 6px solid #f5ba1a;
        width: 50px;
        height: 50px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
}
/* End of smaller screen */

/* Small screen */
@media screen and (min-width: 479px) and (max-width: 767px) {
    .loader1 {
        /* margin: 10% 45%; */
        display: flex;
        justify-content: center;
        align-items: center;
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 6px solid #2980B9;
        border-right: 6px solid #2ECC71;
        border-bottom: 6px solid #e62020;
        border-left: 6px solid #f5ba1a;
        width: 50px;
        height: 50px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
}
/* End of small screen */


/* Media Query section for  Medium screen */
@media screen and (min-width: 768px) and (max-width: 991px){
}
/* End of medium screen */

/* Media Query section for  Medium screen */
@media screen and (min-width: 992px) and (max-width: 1200px){
}