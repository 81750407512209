 /* Dev Name: Valentine Bassey. 
   Email: awasevalentine@gmail.com
   Github: https://github.com/awasevalentine
*/


 .booking-page{
  background-color: #f9f9f9;
  display: block;
  overflow-x: hidden;
  animation: entrance 1.4s;
 }
 .container{
  padding-bottom: 2rem;
 }

 @keyframes entrance {

  0% {
    transform: translateY(-300px);
    opacity: 0;
  }

  100%{
    transform: translateY(0px);
    opacity: 1;
  }
  
 }

 /* Header section */
 
 .header_wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
 }

 .sub_header_wrapper{
  width: 30%;
 }

 .sub-welcome-header{
  float: right;
  font-family: 'Courgette', cursive;
  line-height: 0;
  font-size: 3rem;
  margin-right: 2rem;
  /* color: #FFB562; */
  color: #f3c300;
 }

 /* End of header section */

 .total-wrapper > p{
  font-size: larger;
  font-weight: 600;
  font-family: Lato;
  color: #991037;
}

 .hillsbay_logo{
  width: 80px;
  /* height: 60px; */
  margin-top: 1.5rem;
  object-fit: contain;
 }

 .formError-wrapper {
  color: red;
  margin-bottom: -.8rem;
}

 .welcome-header {
  font-size: 30px;
  color: #991037;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 40px;
  margin-top: 1.5rem;
  text-align: center;
}
.sub-welcome-header{
  font-size: 14px;
  text-align: center;
}
#main-content-wrapper {
        width: 50%;
        box-sizing: border-box;
        font-size: 14px;
        margin: auto;
        margin-top: 2rem;
        border: 1px solid silver;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 5px rgb(198, 197, 197)
  }

  label > span, .errorMsg > span {
    color: red;
  }

  label {
    color: #6d6565;
    font-weight: 400;
  }

  .error {
    border-color: red;
  }

  #profile-section, #room-detail-section {
    background: rgba(255,255,255,1);
    width: 100%;
    padding: 2rem;
    border-radius: 10px;
  }

  /* .header{
    margin: 1.7rem 0px;
  } */

 
  .header-content {
    color: rgba(29,29,29,1);
    font-family: Poppins;
    font-weight: bold;
    line-height: .5;
    /* font-size: 19px; */
  
  }

  .form-field {
    margin-top: 20px;
  }



  /* Section for RoomQuantity design */
  .decrementBtn{
    height: 4px;
    width: 16px;
    background-color: #003865;
    font-size: 25px;
    cursor: pointer;
    font-weight: 900;
    box-sizing: border-box;
    margin-right: 1.5rem;
    justify-items: center;
    align-items: center;

}


.incrementBtn{
    font-size: 25px;
    color: #003865;
    cursor: pointer;
    font-weight: 900;
    box-sizing: border-box;
    margin-left: 1.5rem;
    line-height: 0 !important;

}


.count-wrapper {
    /* font-size: 1.1rem; */
    font-weight: 700;
    padding: .5rem;
}
.count-content-wrapper {
    width: 50px;
    border: none !important;
    text-align: center;
    font-weight: 500;

}

.count-content-wrapper:focus {
    outline: none !important;
}

.form-control:focus, .form-select:focus {
    outline: none !important;
    border-color: gray;
    box-shadow: none;
}

.btn_wrapper{
  margin-top: 1rem;
  width: 100%;
}
.paymentBtn {
  width: 150px;
  font-family: Poppins;
  font-weight: 700;
  color: #fff;
  font-size: 1.1rem;
  background-color: #991037;
  /* background-color: #f28ca9; */
}




  /* Media screen section */

  
  /* For extra smaller screen */
@media screen and (max-width: 320px) {
  #main-content-wrapper {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin: auto;
    margin-top: 2rem;
}

.welcome-header {
  font-size: 23px;
  color: #991037;
  font-weight: 700;
  letter-spacing: 0;
  padding-top: 0;
  margin-top: .7rem;
  text-align: center;
}

  .sub_header_wrapper{
  width: 100%;
  }

  .sub-welcome-header {
  font-size: 14px;
  text-align: center;
  }

  .hillsbay_logo{
    width: 45px;
    /* height: 20px; */
    margin-top: .6rem;
   }

  .paymentBtn {
    width: 100%;
    font-family: Poppins;
    font-weight: 700;
    color: #fff;
    font-size: 1.1rem;
    background-color: #991037;
}

.promo_message{
  font-size: 13px;
}

}

/* End of extra smaller screen */

  /* For smaller screen */
@media screen and (min-width: 321px) and (max-width: 478px) {
  #main-content-wrapper {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin: auto;
    margin-top: 2rem;
}

  .sub_header_wrapper{
  width: 100%;
  }

  .sub-welcome-header {
  font-size: 14px;
  text-align: center;
  margin-right: -35px;
  float: none;
  }

  .welcome-header {
    font-size: 25px;
    color: #991037;
    font-weight: 700;
    letter-spacing: 0;
    padding-top: 0;
    margin-top: .8rem;
    text-align: center;
  }

  .hillsbay_logo{
    width: 45px;
    /* height: 20px; */
    margin-top: .6rem;
   }

  .paymentBtn {
    width: 100%;
    font-family: Poppins;
    font-weight: 700;
    color: #fff;
    font-size: 1.1rem;
    background-color: #991037;
}

.promo_message{
  font-size: 13px;
}

}

/* End of smaller screen */

/* Small screen */
@media screen and (min-width: 479px) and (max-width: 767px) {

  #main-content-wrapper {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin: auto;
    margin-top: 2rem;
}

.sub_header_wrapper{
width: 100%;
}

.sub-welcome-header {
font-size: 14px;
text-align: center;
margin-right: 90px;
}

.welcome-header {
  font-size: 25px;
  color: #991037;
  font-weight: 700;
  letter-spacing: 0;
  padding-top: 0;
  margin-top: 1rem;
  text-align: center;
}

.hillsbay_logo{
  width: 50px;
  /* height: 20px; */
  margin-top: 1rem;
 }
}
/* End of small screen */


/* Media Query section for  Medium screen */
@media screen and (min-width: 768px) and (max-width: 991px){

  .sub_header_wrapper{
    width: 100%;
   }

   .sub-welcome-header {
    font-size: 14px;
    text-align: center;
    margin-right: 180px;
}

#main-content-wrapper {
  width: 80%;
  box-sizing: border-box;
  font-size: 14px;
  margin: auto;
  margin-top: 2rem;
  border: 1px solid silver;
  border-radius: 10px;
}

.welcome-header {
  font-size: 25px;
  color: #991037;
  font-weight: 700;
  letter-spacing: 0;
  padding-top: 0;
  margin-top: 1.2rem;
  text-align: center;
}

.hillsbay_logo{
  width: 60px;
  /* height: 20px; */
  margin-top: 1rem;
 }
}
/* End of medium screen */

/* Media Query section for  Medium screen */
@media screen and (min-width: 992px) and (max-width: 1200px){

  .sub_header_wrapper{
    width: 100%;
   }
   .sub-welcome-header {
    font-size: 14px;
    text-align: center;
    margin-right: 180px;
    float: none;
    margin-right: -120px;
}

#main-content-wrapper {
  width: 80%;
  box-sizing: border-box;
  font-size: 14px;
  margin: auto;
  margin-top: 2rem;
  border: 1px solid silver;
  border-radius: 10px;
}
}
/* End of medium screen */
  